<script>
import * as pbi from "powerbi-client";

export default {
 name: 'PowerBIReport',
 props: {
   powerbireport: {
    type: Object,
    required: true
   },
   showPageNavigation: { type: Boolean, default: true},
 },
 mounted () {
   const permissions = pbi.models.Permissions.View;

   const config = {
     type: 'report',
     tokenType: pbi.models.TokenType.Embed,
     accessToken: this.powerbireport.token,
     embedUrl: this.powerbireport.embedUrl,
     id: this.powerbireport.reportId,
     permissions: permissions,
     settings: {
       filterPaneEnabled: false,
       panes:{
        pageNavigation: {
          visible: this.showPageNavigation,
        }
       }
     }
   };

   let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
   const dashboardContainer = document.getElementById('container');
   const dashboard = powerbi.embed(dashboardContainer, config);

   dashboard.off("loaded");
   dashboard.off("rendered");
   dashboard.on("error", function () {
     this.dashboard.off("error");
   });
  }
 }
</script>

<template>
  <div>
   <section id="container" style="height: 770px" />
  </div>
</template>
